import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import {
  ToastComponent,
  ToastUtility,
} from '@syncfusion/ej2-angular-notifications';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private _toast!: ToastComponent;

  showErrorToast(error: HttpErrorResponse) {
    switch (error.status) {
      case 400:
        {
          const validationErrors: string[] = [];

          for (const value of Object.values(error.error.errors)) {
            if (Array.isArray(value)) {
              validationErrors.push(...value);
            } else {
              validationErrors.push(value as string);
            }
          }

          let toastContent;

          if (validationErrors.length === 1) {
            toastContent = validationErrors[0];
          } else {
            toastContent = `<ul style="padding-right: 16px;">${validationErrors
              .map((error) => `<li>${error}</li>`)
              .join('')}</ul>`;
          }
          this._toast = ToastUtility.show(
            {
              content: toastContent,
              showCloseButton: true,
              // timeOut: 0,
              position: { X: 'Center', Y: 'Top' },
              cssClass:
                'e-toast-danger e-toast-header-icon e-toast-icon e-icons e-toast-error-icon',
            },
            'Error'
          ) as ToastComponent;
        }
        break;
      case 500:
        {
          const errorMessage =
            translate('An error occurred while processing your request') + '.';
          this._toast = ToastUtility.show(
            {
              content: errorMessage,
              showCloseButton: true,
              // timeOut: 0,
              position: { X: 'Center', Y: 'Top' },
              cssClass:
                'e-toast-danger e-toast-header-icon e-toast-icon e-icons e-toast-error-icon',
            },
            'Error'
          ) as ToastComponent;
        }
        break;
      case 404:
        {
          const errorMessage = error.error?.title ?? error.message;
          this._toast = ToastUtility.show(
            {
              content: errorMessage,
              showCloseButton: true,
              // timeOut: 0,
              position: { X: 'Center', Y: 'Top' },
              cssClass:
                'e-toast-danger e-toast-header-icon e-toast-icon e-icons e-toast-error-icon',
            },
            'Error'
          ) as ToastComponent;
        }
        break;
      default:
        {
          const errorMessage =
            translate('Server is not reachable, please try again') + '.';
          this._toast = ToastUtility.show(
            {
              content: errorMessage,
              showCloseButton: true,
              // timeOut: 0,
              position: { X: 'Center', Y: 'Top' },
              cssClass:
                'e-toast-danger e-toast-header-icon e-toast-icon e-icons e-toast-error-icon',
            },
            'Error'
          ) as ToastComponent;
        }
        break;
    }
  }

  showSuccessfulToast(message: string) {
    this._toast = ToastUtility.show(
      {
        content: `${translate(message)}.`,
        showCloseButton: true,
        // timeOut: 0,
        position: { X: 'Center', Y: 'Top' },
        cssClass:
          'e-toast-success e-toast-header-icon e-toast-icon e-icons e-toast-success-icon',
      },
      'Success'
    ) as ToastComponent;
  }

  hideAllToasts() {
    this._toast?.hide('All');
  }
}
